import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import RfNeon from "../images/rfNeonSocial.jpg";
import CumbiaPrivate from "../images/CumbiaPrivate.jpg";
import CumbiaPrivates from "../images/CumbiaPrivateS.jpg";
import Cumbiatexana from "../images/cumbiatexana.jpg";
import RfDiscoSocial from "../images/RfDiscoSocial.jpg";
import Banda from "../images/classbanda.jpg";
import quebraditaWorkshop from "../images/quebraditaWorkshop.jpg";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}
              <h3>English:</h3>
              <div className="english-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=85&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/31/24">
                      <img src={quebraditaWorkshop} alt="Quebradita Workshop" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Quebradita Workshop</h3>
                    <div className="news_content_area">
                      <p>
                        You're invited to join an exhilarating Quebradita
                        Workshop featuring Friné and Jorge, who are coming from
                        Mexico City. The workshop will take place on Saturday,
                        August 31st, from 12:00 pm to 1:30 pm at RF Dance
                        Center, 1517 N. Main St, Santa Ana, CA 92701. Do not
                        miss the opportunity to take a one-time class to learn
                        Quebradita from skilled professionals. Pre-sale tickets
                        are available for $40 (non-refundable), and tickets at
                        the door will be $50. RF Members can enjoy a 50%
                        discount. This is a must-attend event to refine your
                        dance skills. See you soon!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=85&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/31/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=84&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/29/24">
                      <img src={Cumbiatexana} alt="Cumbia Texana" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Cumbia Texana Class</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a special one-time Cumbia Texana class with
                        Friné Garrey and Jorge Santana from Mexico on Thursday,
                        August 29th, from 8:00 p.m. to 9:00 p.m. at RF Dance
                        Center, 1517 N. Main St, Santa Ana, CA 92701. Take
                        advantage of this unique opportunity to learn from the
                        best! It's only $30 (non-refundable) and free for RF
                        Members. Secure your spot now.
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=84&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/29/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=08/30/24">
                      <img src={RfNeon} alt="RF Neon Social" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Neon Social</h3>
                    <div className="news_content_area">
                      <p>
                        Get ready for our RF Neon Social! Join us on Friday,
                        August 30th, from 9:00 pm to 1:00 am at RF Dance Center.
                        Kick off with a Banda class by Friné Garrey & Jorge
                        Santana, who are being brought in all the way from
                        Mexico, at 9:00 pm. Then, dance the night away with DJ
                        Real Deal and DJ Noe till 1:00 am. Make sure to wear
                        your brightest and most vibrant neon attire to light up
                        the night! Pre-sale tickets are $25 (non-refundable), or
                        pay $35 at the door. RF Members can enjoy free entry
                        from 9:00 pm to 11:00 pm, but make sure to pre-register
                        before Friday. Secure your spot now for an unforgettable
                        night of dancing, music, and neon fun! &#127775;
                        &#128378; &#127881;
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=08/30/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=09/13/24">
                      <img src={Banda} alt="Banda Class" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class</h3>
                    <div className="news_content_area">
                      <p>
                        <strong>Get Ready to Learn some Banda!</strong> Join us
                        for a fun Banda Class led by Christopher & Destany on
                        Friday, September 13th at 9:00 pm at RF Dance Center.
                        Secure your pre-sale spot for $20 (Non-Refundable), or
                        pay $30 at the door. RF Members attend for free. Don't
                        just sit around at parties when the band is playing! No
                        partner is necessary, and all levels are welcome. Stick
                        around after the class for an extra hour of practice and
                        social dancing to Cumbia and Banda rhythms! Secure your
                        spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=09/13/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=09/27/24">
                      <img src={RfDiscoSocial} alt="RfDiscoSocial" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Disco Social</h3>
                    <div className="news_content_area">
                      <p>
                        Get ready for our RF Disco Social! Join us on Friday,
                        September 27th, from 9:00 pm to 1:00 am at RF Dance
                        Center. Kick off with a Banda class by Dave & Erika at
                        9:00 pm, then dance the night away with DJ Real Deal and
                        DJ Noe till 1am. Make sure to wear your grooviest 70's
                        disco attire to light up the night! Pre-sale tickets are
                        $25 (non-refundable), or pay $35 at the door. RF Members
                        can enjoy free entry from 9:00 pm to 11:00 pm, but make
                        sure to pre-register before Friday. Secure your spot now
                        for an unforgettable night of dancing, music, and disco
                        fun! &#127775; &#128378; &#127881;
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=09/27/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div> */}

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://app.acuityscheduling.com/schedule.php%20owner=17766906&appointmentType=59713489">
                      <img
                        src={CumbiaPrivate}
                        alt=" Cumbia Private Lesson Summer Special (with Kike) "
                      />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">
                      Cumbia Private Lesson Summer Special (with Kike)
                    </h3>
                    <div className="news_content_area">
                      <p>
                        Elevate your dance skills this summer with exclusive
                        Cumbia private lessons from none other than Kique, the
                        current Reno Latin Dance Festival Jack and Jill
                        Champion! With over 6 years of teaching experience,
                        Kique brings expertise and passion to every 50-minute
                        session.
                      </p>
                      <h4>Summer Special Pricing:</h4>
                      <ul>
                        <li>
                          <p>RF Members: $79</p>
                        </li>
                        <li>
                          <p>Non-Members: $99</p>
                        </li>
                      </ul>
                      <p>
                        Don't miss out on this opportunity to learn from a
                        master. Book your exclusive Cumbia lesson with Kique
                        now!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://app.acuityscheduling.com/schedule.php%20owner=17766906&appointmentType=59713489">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=85&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/31/24">
                      <img
                        src={quebraditaWorkshop}
                        alt="Taller de Quebradita"
                      />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Taller de Quebradita</h3>
                    <div className="news_content_area">
                      <p>
                        Estás invitado a un emocionante Taller de Quebradita con
                        Friné y Jorge, que vienen desde la Ciudad de México! El
                        taller se llevará a cabo el sábado 31 de agosto, de 2:30
                        pm a 4:00 pm en RF Dance Center, 1517 N. Main St, Santa
                        Ana, CA 92701. No pierdas la oportunidad de tomar una
                        clase única para aprender Quebradita de profesionales
                        expertos. Los boletos en preventa están disponibles por
                        $40 (no reembolsables), y los boletos en la puerta serán
                        $50. Los miembros de RF pueden disfrutar de un descuento
                        del 50%. ¡Este es un evento imperdible para aprender un
                        nuevo estilo y pasar un rato fantástico!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=85&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/31/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=84&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/29/24">
                      <img src={Cumbiatexana} alt="Cumbia Texana" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Cumbia Texana Class</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a nosotros para una clase especial única de Cumbia
                        Texana con Friné Garrey y Jorge Santana desde México, el
                        jueves 29 de agosto, de 8:00 p.m. a 9:00 p.m. en RF
                        Dance Center, 1517 N. Main St, Santa Ana, CA 92701.
                        Aprovecha esta oportunidad única de aprender de los
                        mejores! Solo cuesta $30 (no reembolsable) y es gratis
                        para los miembros de RF. ¡Asegura tu lugar ahora!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=46&sVT=84&sLevel=4&sView=day&sLoc=1&sTrn=100000157&date=08/29/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=08/30/24">
                      <img src={RfNeon} alt="RF Neon Social" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Neon Social</h3>
                    <div className="news_content_area">
                      <p>
                        {" "}
                        Prepárate para nuestro RF Neon Social! Únete a nosotros
                        el viernes 30 de agosto, de 9:00 pm a 1:00 am en RF
                        Dance Center. Comienza con una clase de Banda impartida
                        por Friné Garrey y Jorge Santana, quienes vienen desde
                        México, a las 9:00 pm. Luego, baila toda la noche con DJ
                        Real Deal y DJ Noe hasta la 1:00 am. ¡Asegúrate de
                        llevar tu atuendo de neón más brillante y vibrante para
                        iluminar la noche! Los boletos en preventa cuestan $25
                        (no reembolsables), o paga $35 en la puerta. Los
                        miembros de RF pueden disfrutar de entrada gratuita de
                        9:00 pm a 11:00 pm, pero asegúrate de pre-registrarte
                        antes del viernes. ¡Asegura tu lugar ahora para una
                        noche inolvidable de baile, música y diversión de neón!
                        &#127775; &#128378; &#127881;
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=08/30/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=09/13/24">
                      <img src={Banda} alt="Clase de Banda" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda</h3>
                    <div className="news_content_area">
                      <p>
                        Prepárate para aprender a bailar Banda! Únete a nosotros
                        para una divertida clase de Banda dirigida por
                        Christopher y Destany el viernes 13 de septiembre a las
                        9:00 pm en RF Dance Center. Asegura tu lugar en preventa
                        por $20 (No Reembolsables) o paga $30 en la puerta. Los
                        miembros de RF asisten gratis. ¡No te quedes sentado en
                        las fiestas cuando suene la banda! No necesitas pareja y
                        todos los niveles son bienvenidos. Quédate después de la
                        clase para una hora extra de práctica y baile social con
                        ritmos de Cumbia y Banda. ¡Asegura tu lugar y únete a la
                        fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=09/13/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=09/27/24">
                      <img src={RfDiscoSocial} alt="RfDiscoSocial" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Disco Social</h3>
                    <div className="news_content_area">
                      <p>
                        Prepárate para nuestro RF Disco Social! Únete a nosotros
                        el viernes 27 de septiembre, de 9:00 pm a 1:00 am en el
                        RF Dance Center. Comienza con la clase de Banda de Dave
                        y Erika a las 9:00 pm, luego baila toda la noche con DJ
                        Real Deal y DJ Noe hasta la 1:00 am. ¡Viste tu atuendo
                        disco más brillante y vibrante para iluminar la noche!
                        Los boletos en preventa cuestan $25 (no rembolsables), o
                        $35 en la puerta. Los miembros de RF pueden disfrutar de
                        entrada gratuita de 9:00 pm a 11:00 pm, pero asegúrate
                        de registrarte antes del viernes. ¡Asegura tu lugar
                        ahora para una noche inolvidable de baile, música y
                        diversión disco! &#127775; &#128378; &#127881;
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=09/27/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div> */}

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=45&sVT=73&sLevel=4&sView=day&sLoc=1&date=05/17/24">
                      <img src={CumbiaPrivates} alt=" Banda Class " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">
                      Lección privada de cumbia especial de verano (con Kike)
                    </h3>
                    <div className="news_content_area">
                      <p>
                        Eleva tus habilidades de baile este verano con clases
                        privadas exclusivas de Cumbia impartidas por nadie menos
                        que Kique, el actual campeón de Jack and Jill del
                        Festival de Baile Latino de Reno! Con más de 6 años de
                        experiencia en la enseñanza, Kique trae su experiencia y
                        pasión a cada sesión de 50 minutos.
                      </p>
                      <h4>Precios Especiales de Verano:</h4>
                      <ul>
                        <li>
                          <p>Miembros RF: $79</p>
                        </li>
                        <li>
                          <p>No Miembros: $99</p>
                        </li>
                      </ul>
                      <p>
                        No pierdas esta oportunidad de aprender de un maestro.
                        ¡Reserva tu clase exclusiva de Cumbia con Kique ahora!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://app.acuityscheduling.com/schedule.php%20owner=17766906&appointmentType=59713489">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
